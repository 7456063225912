<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu :admin-title="adminTitle" />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link to="/" tag="a" class="intro-x flex items-center">
          <img
            :alt="adminTitle"
            :title="adminTitle"
            class="side-nav__logo"
            src="@/assets/images/CISA.svg"
          />
          <span class="hidden xl:block text-white text-base text-2xl">
            CISA
          </span>
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else-if="setmap(menu.to)" :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                href="javascript:;"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router)"
              >
                <div class="side-menu__icon">
                  <FontAwesome
                    v-if="menu.icon.startsWith('fa-')"
                    class="w-6 h-6 m-auto"
                    :icon="menu.icon.substr(3)"
                  />
                  <img
                    v-else-if="menu.icon.includes('/')"
                    :src="menu.icon"
                    class="m-auto"
                  />
                  <component v-else :is="menu.icon" class="m-auto" />
                </div>
                <div class="side-menu__title">
                  <span class="side-menu__title-text">{{ menu.title }}</span>
                  <div
                    v-if="menu.subNodes"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subNodes && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subNodes"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      href="javascript:;"
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router)"
                    >
                      <div class="side-menu__icon">
                        <FontAwesome
                          v-if="subMenu.icon.startsWith('fa-')"
                          class="w-6 h-6 m-auto"
                          :icon="subMenu.icon.substr(3)"
                        />
                        <img
                          v-else-if="subMenu.icon.includes('/')"
                          :src="subMenu.icon"
                          class="m-auto"
                        />
                        <component
                          v-else-if="subMenu.icon"
                          :is="subMenu.icon"
                          class="m-auto"
                        />
                        <ActivityIcon v-else class="m-auto" />
                      </div>
                      <div class="side-menu__title">
                        <span class="side-menu__title-text">{{
                          subMenu.title
                        }}</span>
                        <div
                          v-if="subMenu.subNodes"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subNodes && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subNodes"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            href="javascript:;"
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router)"
                          >
                            <div class="side-menu__icon">
                              <FontAwesome
                                v-if="lastSubMenu.icon.startsWith('fa-')"
                                class="w-6 h-6 m-auto"
                                :icon="lastSubMenu.icon.substr(3)"
                              />
                              <img
                                v-else-if="lastSubMenu.icon.includes('/')"
                                :src="lastSubMenu.icon"
                                class="m-auto"
                              />
                              <component
                                v-else-if="lastSubMenu.icon"
                                :is="lastSubMenu.icon"
                                class="m-auto"
                              />
                              <ActivityIcon v-else class="m-auto" />
                            </div>
                            <div class="side-menu__title">
                              <span class="side-menu__title-text">{{
                                lastSubMenu.title
                              }}</span>
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
    <footer
      class="flex justify-center text-white dark:text-white text-xs sm:text-sm mt-4"
    >
      Copyright&nbsp;&copy;2021&ensp;CISA
    </footer>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  onUnmounted
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, enter, leave } from "./index";
import CloudFun from "@cloudfun/core";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const application = CloudFun.current;
    const policy = application?.policy;
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const sideMenu = computed(() => policy?.makeMenu() || []);
    const model = CloudFun.current?.model;

    const auth = ref<any>({});
    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    // const adminTitle = computed(() => application?.model.state.configuration.value.AdminTitle || "中華軟體協會")
    const adminTitle = computed(() => "CISA");

    const isVendor = ref(false);

    /// 0.一般訪客 1.負責人 2.特助 3.產品經理 4.業務 5.買家
    const positionList = [
      ["/dashboard", "/announcement", "/user"],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user",
        // "/company/application",
        "/review/apply"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user",
        // "/company/application",
        "/review/apply"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
        // "/company/application"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/company",
        "/user"
        // "/company",
        // "/company/application"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/user",
        // "/company/upgrade",
        "/company/application"
      ]
    ];

    const positions = ref<any>([]);

    const loadAuthInfo = async () => {
      auth.value = await getAuth();
      console.log("auth.value", auth.value);
      if (auth.value) {
        if (
          auth.value.position === 0 &&
          auth.value.isBuyer === false &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[0];
        } else if (
          auth.value.position === 0 &&
          auth.value.isBuyer === true &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[5];
        } else if (auth.value.isVendor === true) {
          positions.value = positionList[auth.value.position];
        }
      }
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    onMounted(async () => {
      loadAuthInfo();
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
    });

    // onUnmounted(() => {});

    const setmap = (name: string) => {
      return positions.value.includes(name);
    };

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      adminTitle,
      setmap,
      loadAuthInfo
    };
  }
});
</script>

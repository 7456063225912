
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  onUnmounted
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, enter, leave } from "./index";
import CloudFun from "@cloudfun/core";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const application = CloudFun.current;
    const policy = application?.policy;
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const sideMenu = computed(() => policy?.makeMenu() || []);
    const model = CloudFun.current?.model;

    const auth = ref<any>({});
    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    // const adminTitle = computed(() => application?.model.state.configuration.value.AdminTitle || "中華軟體協會")
    const adminTitle = computed(() => "CISA");

    const isVendor = ref(false);

    /// 0.一般訪客 1.負責人 2.特助 3.產品經理 4.業務 5.買家
    const positionList = [
      ["/dashboard", "/announcement", "/user"],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user",
        // "/company/application",
        "/review/apply"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user",
        // "/company/application",
        "/review/apply"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/member",
        "/company",
        "/user"
        // "/company/application"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/product",
        "/company",
        "/user"
        // "/company",
        // "/company/application"
      ],
      [
        "/dashboard",
        "/announcement",
        "/meeting",
        "/user",
        // "/company/upgrade",
        "/company/application"
      ]
    ];

    const positions = ref<any>([]);

    const loadAuthInfo = async () => {
      auth.value = await getAuth();
      console.log("auth.value", auth.value);
      if (auth.value) {
        if (
          auth.value.position === 0 &&
          auth.value.isBuyer === false &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[0];
        } else if (
          auth.value.position === 0 &&
          auth.value.isBuyer === true &&
          auth.value.isVendor === false
        ) {
          positions.value = positionList[5];
        } else if (auth.value.isVendor === true) {
          positions.value = positionList[auth.value.position];
        }
      }
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    onMounted(async () => {
      loadAuthInfo();
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
    });

    // onUnmounted(() => {});

    const setmap = (name: string) => {
      return positions.value.includes(name);
    };

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      adminTitle,
      setmap,
      loadAuthInfo
    };
  }
});
